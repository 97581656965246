/* global Promise */

'use strict';

angular.module('managerApp').factory('$_myCookies', function ($cookies) {
    return {
        set (cookieName, cookieValue, cookieDuration = null) {
            if (!cookieName || !cookieValue) {
                return;
            }
            if (typeof cookieValue === 'object') {
                cookieValue = JSON.stringify(cookieValue);
            }
            let cookieOptions = {};
            if (cookieDuration !== null) {
                cookieDuration = cookieDuration * 1000;
                let expirationDate = new Date();
                expirationDate.setTime(expirationDate.getTime() + cookieDuration);
                cookieOptions.expires = expirationDate;
            }
            $cookies.put(cookieName, cookieValue, cookieOptions);
        },
        get (cookieName) {
            let data = $cookies.get(cookieName);
            if (data) {
                try {
                    let obj = JSON.parse(data);
                    return obj;
                } catch (e) {
                    return data;
                }
            }
            return null;
        },
        getOrSet(cookieName, cookieValue){
           let v = this.get(cookieName);
           if(!v){
               this.set(cookieName, cookieValue);
               return cookieValue;
           }
           return v;
        }
    };
});